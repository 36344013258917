import { App } from './App';
import gsap from 'gsap';

export const mouse = {
    x: 0,
    y: 0
};

const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
};

window.addEventListener('resize', () => {
    sizes.width = window.innerWidth;
    sizes.height = window.innerHeight;
});

window.addEventListener('mousemove', (event) => {
    if (App.mobile) {
        return;
    }

    gsap.to(mouse, {
        duration: 1.5,
        x: event.clientX / sizes.width * 2 - 1,
        ease: 'power3.out'
    });

    gsap.to(mouse, {
        duration: 1.5,
        y: - (event.clientY / sizes.height) * 2 + 1,
        ease: 'power3.out'
    });
});


/**
 * Accelerometer
 */
window.addEventListener('deviceorientation', (event) => {
    if (!App.mobile) {
        return;
    }

    gsap.to(mouse, {
        duration: 1.5,
        x: event.gamma * -0.01,
        ease: 'power3.out'
    });

    gsap.to(mouse, {
        duration: 1.5,
        y: event.beta * -0.01,
        ease: 'power3.out'
    });
});
