import { App } from './App';
import gsap from 'gsap';

const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
};
let scrollY = window.scrollY;
let clientY = 0;
export const hoverCoord = {
    x: 0.5,
    y: 0
};


window.addEventListener('resize', () => {
    setTimeout(() => {
        sizes.width = window.innerWidth;
        sizes.height = window.innerHeight;
    }, 300);
});


if (!App.mobile) {
    window.addEventListener('mousemove', (event) => {
        scrollY = window.scrollY;
        clientY = event.clientY;

        gsap.to(hoverCoord, {
            duration: 1.5,
            x: event.clientX / sizes.width,
            ease: 'power3.out'
        });

        gsap.to(hoverCoord, {
            duration: 1.5,
            y: (clientY + scrollY - getRectTop()) / sizes.width,
            ease: 'power3.out'
        });
    });


    window.addEventListener('scroll', () => {
        scrollY = window.scrollY;

        gsap.to(hoverCoord, {
            duration: 1.5,
            y: (clientY + scrollY - getRectTop()) / sizes.width,
            ease: 'power3.out'
        });
    });
}


function getRectTop() {
    return sizes.height / 2 - sizes.width / 2;
}
